import React,{useEffect} from "react";
import { Header, Aside } from "../components";
import { useSelector,useDispatch } from "react-redux";
import firebase from "firebase";
import API from '../axios.js';
import { Alert, Snackbar } from "@mui/material";
import { sPopup } from "../store/reducer";

export default function Layout({ children }) {
    const user=useSelector((state) => state.storeReducer.user);
    const s_Popup=useSelector((state) => state.storeReducer.s_Popup);
    const [open, setOpen] = React.useState(false);

    const messaging = firebase.messaging();
    const dispatch = useDispatch();
  
    useEffect(() => {
        API('check-token','get',null).then((res) => {
        }).catch((err) => {
            // console.log("🚀 ~ file: index.jsx ~ line 20 ~ API ~ err", err)
            initFirebaseMessagingRegistration();
        });
    }, []);

    const toggleDrawer = event => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
  
      setOpen(!open);
    };

    function initFirebaseMessagingRegistration() {
      messaging
        .requestPermission()
        .then(function () {
          return messaging.getToken({
            vapidKey:process.env.REACT_APP_FCM_PROJECT_KEY,
          });
        })
        .then(function (token) {
          API('save-pn-token','post',{token:token}).then((res) => {
          }).catch((err) => {
          })
        })
        .catch(function (err) {
        // console.log("🚀 ~ file: index.jsx ~ line 38 ~ initFirebaseMessagingRegistration ~ err", err)
        });
    }
  
    messaging.onMessage(payload => {
      console.log("foreground!");
      const noteTitle = payload.data.title;
      const noteOptions = {
        body: payload.data.body,
        icon: payload.data.icon,
      };
      notifyMe(noteTitle, noteOptions, payload);
    });
   
    function notifyMe(text, noteOptions, payload) {
      if (Notification.permission !== "granted") Notification.requestPermission();
      else {
        var notification = new Notification(text, {
          body: noteOptions.body,
          icon: noteOptions.icon,
          requireInteraction: true,
        });
        
        notification.addEventListener("click", ()=> {
          window.open(`/call/${payload.data.room_name}`,'_blank')
        });
      }
    }

return (
    <>
        <div className="az_laytout_99">
            <Aside open={open} toggleDrawer={toggleDrawer}/>
            <div className="az_main">
                <Header user={user} toggleDrawer={toggleDrawer}/>
                <main className="main_content">{children}</main>
            </div>
            <Snackbar 
            open={s_Popup.open} 
            autoHideDuration={3000}
            onClose={()=>{
              dispatch(sPopup({open:false,message:''}))
            }}>
              <Alert onClose={()=>{
                dispatch(sPopup({open:false,message:''}))
              }} severity="success" sx={{ width: '100%' }}>
                {s_Popup.message}
              </Alert>
            </Snackbar>
            <Snackbar 
            open={s_Popup.open} 
            autoHideDuration={3000}
            onClose={()=>{
              dispatch(sPopup({open:false,message:''}))
            }}>
              <Alert onClose={()=>{
                dispatch(sPopup({open:false,message:''}))
              }} severity="success" sx={{ width: '100%' }}>
                {s_Popup.message}
              </Alert>
            </Snackbar>
        </div>
    </>
);
}
