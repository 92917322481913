import { Box, Button, Modal, Stack, Switch, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import API from '../../axios';
import { MyLoader } from '../../components';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Container } from '@mui/system';
import { useDispatch } from 'react-redux';
import { sPopup } from '../../store/reducer';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteBtn = (props) => {
    return (
        <Button onClick={() => props.showModal(props.params)}><DeleteIcon sx={{color: 'red'}} /></Button>
    )
};

export default function InsuranceCarriers() {
    const [isLoading, setIsLoading] = useState();
    const [records, setRecords] = useState('');
    const [rows, setRows] = useState([]);
    const [newForm, setNewForm] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [formData, setFormData] = useState({
        title: ''
    });
    const [updateCategory, setUpdateCategory] = useState(null);
    const dispatch = useDispatch();
    const [InsuranceID, setInsuranceID] = useState(null);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        getRecords();
    }, []);

    const createRows = (data) => {
        setRows([]);
        data.map(item => {
            setRows(prev => [...prev, {
                id: item.insurance_carrier_id,
                Property_Type: item.text,
                Status: item.active,
            }])
        })
    }

    const getRecords = () => {
        API('admin/insurance-carriers', 'get').then(res => {
            let response = res.data.response.detail.insurance_carriers;
            setRecords(response);
            createRows(response);
        }).catch(err => {
            console.log("🚀 ~ file: property-types.jsx ~ API ~ err", err);
        })
    }

    const updateStatus = (props) => {
        const userId = props.id;
        const status = props.row.Status;
        let fd = new FormData();
        if (status == true) {
            fd.append('status', "disabled");
        } else {
            fd.append('status', "active");
        }
        setIsLoading(true);
        API(`admin/insurance-carrier/${userId}`, 'post', fd).then(res => {
            setIsLoading(false);
            let msg = res.data.response.message;
            dispatch(sPopup({ open: true, message: msg }));
            return
            const userRecord = res.data.response.detail.training_category;
            let index = records.training_categories?.data?.findIndex(user => user.training_category_id == userId);
            records.training_categories.data[index] = userRecord;
            setRecords(records);
            createRows(records.training_categories.data);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    const submitForm = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let fd = new FormData();
        fd.append('text', formData.title);
        let url = newForm ? 'admin/insurance-carrier' : `admin/insurance-carrier/${updateCategory.id}`;
        try {
            API(url, 'post', fd).then((res) => {
                setFormData({ title: '' });
                let msg = res.data.response.message;
                dispatch(sPopup({ open: true, message: msg }));
                getRecords();
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
            })
        } catch (e) {
            console.log("🚀 ~ file: property-types.jsx ~ line 73 ~ submitForm ~ e", e)
            setIsLoading(false);
        }
    }

    const handleInput = (e) => {
        setFormData({ ...formData, title: e.target.value });
    }

    const updateCat = (item) => {
        setUpdateCategory(item);
        setFormData({ ...formData, title: item.row.Property_Type });
        setEditForm(true);
        setNewForm(false);
    }

    const openForm = () => {
        setFormData({ ...formData, title: '' });
        setNewForm(true);
        setEditForm(false);
    }

    const deleteInsurance = (id) => {
        console.log("🚀 ~ file: category.jsx ~ line 138 ~ deleteTraining ~ id", id)
        setIsLoading(true);
        API(`admin/delete-insurance-carrier/${id}`, 'get', null).then(res => {
            console.log("🚀 ~ file: listing.jsx ~ line 150 ~ API ~ res", res)
            setOpen(false);
            let msg = res.data.response.message;
            dispatch(sPopup({ open: true, message: msg }));
            getRecords();
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    const showModal = (props) => {
        setOpen(true);
        setInsuranceID(props.row.id);
    }

    const closeModal = () => {
        setOpen(false);
        setInsuranceID(null);
    }

    return (
        <>
            <Head />
            {isLoading ?
                <MyLoader />
                :
                null
            }
            <Container maxWidth='xl'>
                {newForm ?
                    <form autoComplete='off' onSubmit={submitForm} style={{ marginTop: '3rem' }}>
                        <Typography variant="h5" component="h5">
                            Add New Insurance Carrier
                        </Typography>
                        <Stack direction="column" spacing={2}>
                            <TextField style={{ width: "100%", marginTop: "0.5rem" }} name="titleValue" id="standard-basic" type="text" label="Title" variant="standard" value={formData.title}
                                onChange={(event) => handleInput(event)}
                                required />
                            <div style={{ alignSelf: 'end' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ marginTop: "1rem", textTransform: "capitalize", padding: "0.5rem 3rem", }}
                                >
                                    Send
                                </Button>
                            </div>
                        </Stack>
                    </form>
                    : null
                }
                {editForm ?
                    <form autoComplete='off' onSubmit={submitForm} style={{ marginTop: '3rem' }}>
                        <Typography variant="h5" component="h5">
                            Edit Insurance Carrier <span style={{ opacity: '0.5' }}>({updateCategory.row.Property_Type})</span>
                        </Typography>
                        <Stack direction="column" spacing={2}>
                            <TextField style={{ width: "100%", marginTop: "0.5rem" }} name="titleValue" id="standard-basic" type="text" label="Title" variant="standard" value={formData.title}
                                onChange={(event) => handleInput(event)}
                                required />
                            <div style={{ alignSelf: 'end' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ marginTop: "1rem", textTransform: "capitalize", padding: "0.5rem 3rem", }}
                                >
                                    Send
                                </Button>
                            </div>
                        </Stack>
                    </form>
                    : null
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4" component="h4" marginTop='2rem'>
                        Insurance Carrier
                    </Typography>
                    {!newForm ?
                        <Button
                            type="button"
                            variant="contained"
                            sx={{ marginTop: "2rem", textTransform: "capitalize" }}

                            endIcon={<AddIcon />}
                            onClick={() => openForm()}
                        >
                            Add New
                        </Button>
                        : null
                    }
                </div>
                <div style={{ width: '100%', margin: '2rem auto' }}>
                    <DataGrid
                        columns={[
                            { field: 'Property_Type', headerName: 'Title', flex: 1 },
                            { field: 'Status', headerName: 'Status', flex: 1, renderCell: (params) => <Switch defaultChecked={params.row.Status ? true : false} onClick={() => updateStatus(params)} /> },
                            { field: 'Action', headerName: 'Action', flex: 1, renderCell: (params) => <div><UpdateBtn params={params} updateCat={updateCat} /><DeleteBtn params={params} showModal={showModal} /></div> }
                        ]}
                        loading={records === ''}
                        rows={rows}
                        autoHeight={true}
                        rowsPerPageOptions={[10]}
                    />
                </div>
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are You Sure?
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="end" mt={2}>
                        <Button variant="outlined" onClick={() => closeModal()}>No</Button>
                        <Button variant="contained" onClick={() => deleteInsurance(InsuranceID)}>yes</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}

const Head = () => {
    return (
        <Helmet>
            <title>Insurance Carriers | Dashboard</title>
        </Helmet>
    );
};

const UpdateBtn = (props) => {
    return (
        <Button onClick={() => props.updateCat(props.params)}><ModeEditIcon /></Button>
    )
};