import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import API from "../../axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { sPopup } from "../../store/reducer";
import { useDispatch } from "react-redux";
import { MyLoader, CustomSelect } from "../../components";

export default function AddJob({ refreshRecords }) {
  const [formData, setFormData] = useState({
    inspector_id: "",
    title: "",
    address: "",
    state: "",
    postal_code: "",
    city: "",
    phone: "",
    email: "",
    feature_image: "",
    other_images: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [imgUrl, setImgUrl] = useState("");
  const [galleryImagesStack, setGalleryImagesStack] = useState([]);
  const [users, setUsers] = useState([]);
  const [featureImgPreview, setFeatureImgPreview] = useState(false);
  const [galleryImgPreview, setGalleryImgPreview] = useState(false);

  const getUsers = () => {
    API("admin/all-users-without-pg", "post", null)
      .then((res) => {
        let response = res?.data?.response?.detail?.users;
        setUsers(response);
      })
      .catch((err) => {
        console.log("🚀 ~ file: jobs.jsx ~ API ~ err", err);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onImageChange = (e) => {
    const [file] = e.target.files;
    setFormData({ ...formData, feature_image: file });
    setImgUrl(URL.createObjectURL(file));
  };

  const galleryImages = (e) => {
    const [file] = e.target.files;
    let arr = formData.other_images;
    arr.push(file);
    setFormData({ ...formData, other_images: arr });
    setGalleryImagesStack([...galleryImagesStack, URL.createObjectURL(file)]);
  };

  const resetForm = () => {
    let resetValues = {
      inspecter: "",
      title: "",
      address: "",
      state: "",
      postal_code: "",
      city: "",
      phone: "",
      email: "",
      feature_image: "",
      other_images: [],
    };
    setImgUrl("");
    setGalleryImagesStack([]);
    setFeatureImgPreview(false);
    setGalleryImgPreview(false);
    setFormData(resetValues);
  };

  const setInspector = (id) => {
    setFormData({ ...formData, inspector_id: id });
  };

  const submitForm = (e) => {
    e.preventDefault();

    let fd = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      if (key == "other_images") return;
      fd.append(key, value);
    });

    if (formData.other_images.length) {
      for (let i = 0; i < formData.other_images.length; i++) {
        fd.append("other_images[]", formData.other_images[i]);
      }
    }
    setIsLoading(true);
    API("admin/job", "post", fd)
      .then((res) => {
        resetForm();
        let msg = res.data.response.message;
        setIsLoading(false);
        refreshRecords();
        dispatch(sPopup({ open: true, message: msg }));
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("🚀 ~ file: jobs.jsx ~ API ~ err", err);
      });
  };

  return (
    <>
      {isLoading ? <MyLoader /> : null}
      <form autoComplete="off" onSubmit={submitForm}>
        <Grid container spacing={2} mt="1rem">
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              name="title"
              id="standard-basic"
              type="text"
              label="Title"
              variant="standard"
              value={formData.title}
              onChange={(event) => handleInput(event)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              name="email"
              id="standard-basic"
              type="email"
              label="Email"
              variant="standard"
              value={formData.email}
              onChange={(event) => handleInput(event)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} alignSelf="flex-end">
            <CustomSelect
              label="Select Option"
              data={users}
              setInspector={setInspector}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              style={{ width: "100%" }}
              name="address"
              id="standard-basic"
              type="text"
              label="Address"
              variant="standard"
              value={formData.address}
              onChange={(event) => handleInput(event)}
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              style={{ width: "100%" }}
              name="phone"
              id="standard-basic"
              type="text"
              label="Phone Number"
              variant="standard"
              value={formData.phone}
              onChange={(event) => handleInput(event)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              name="postal_code"
              id="standard-basic"
              type="text"
              label="Postal Code"
              variant="standard"
              value={formData.postal_code}
              onChange={(event) => handleInput(event)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              name="state"
              id="standard-basic"
              type="text"
              label="State"
              variant="standard"
              value={formData.state}
              onChange={(event) => handleInput(event)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              style={{ width: "100%" }}
              name="city"
              id="standard-basic"
              type="text"
              label="City"
              variant="standard"
              value={formData.city}
              onChange={(event) => handleInput(event)}
              required
            />
          </Grid>

          {/* Feature Image Section */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h5"
              style={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Feature Image
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>             
              <FormControl>
                <input
                  style={{ width: "100%", cursor: "pointer" }}
                  id="standard-basic"
                  onChange={onImageChange}
                  multiple
                  type="file"
                  accept="image/*"
                />
              </FormControl>
              {formData.feature_image !== "" ? (
                <Tooltip title="Preview">
                  <IconButton
                    onClick={() => setFeatureImgPreview(!featureImgPreview)}
                  >
                    {featureImgPreview ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </Tooltip>
              ) : null}
            </Stack>
            {featureImgPreview ? (
              <div style={{ marginTop: "0.5rem" }}>
                <img
                  src={imgUrl}
                  alt=""
                  style={{ width: "100%", maxWidth: "120px", height: "auto" }}
                />
              </div>
            ) : null}
          </Grid>

          {/* Gallery Image Section */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="h5"
              style={{ marginTop: "2rem" }}
            >
              Gallery Image
            </Typography>
            <Stack direction="row" alignItems="center" mt={"1rem"} spacing={2}>
              <Fab variant="extended" size="medium">
                <label htmlFor="galleryImages">
                  Uploaded Images
                  <FormControl variant="standard">
                    <input
                      id="galleryImages"
                      style={{ display: "none" }}
                      onChange={galleryImages}
                      multiple
                      type="file"
                      accept="image/*"
                    />
                  </FormControl>
                </label>
              </Fab>
              {formData.other_images.length ? (
                <Tooltip title="Preview">
                  <IconButton
                    onClick={() => setGalleryImgPreview(!galleryImgPreview)}
                  >
                    {galleryImgPreview ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </Tooltip>
              ) : null}
            </Stack>
            {galleryImgPreview ? (
              <div style={flexWrapper}>
                {galleryImagesStack.map((url, index) => {
                  return (
                    <img
                      src={url}
                      key={index}
                      alt=""
                      style={{
                        margin: "0 0.5rem",
                        width: "100%",
                        maxWidth: "120px",
                        height: "auto",
                      }}
                    />
                  );
                })}
              </div>
            ) : null}
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Box>
                <Button
                  type="button"
                  variant="contained"
                  sx={{
                    marginTop: "1rem",
                    textTransform: "capitalize",
                    padding: "0.5rem 3rem",
                  }}
                  onClick={resetForm}
                >
                  Reset
                </Button>{" "}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    marginTop: "1rem",
                    textTransform: "capitalize",
                    padding: "0.5rem 3rem",
                  }}
                >
                  Send
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

const flexWrapper = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  marginTop: "1rem",
};
