import React, { useEffect, useState } from "react";
import { Container } from "@mui/system";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Fab,
  Grid,
  Modal,
  Pagination,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sPopup } from "../../store/reducer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import API from "../../axios";

export default function Inspection() {
  const [records, setRecords] = useState({});
  const [singleInspection, setSingleInspection] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [InspecID, setInspecID] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getRecords();
  }, [page]);

  const getRecords = () => {
    setIsLoading(true);
    let fd = new FormData();
    fd.append("page", page);
    API("admin/get-inspections", "post", fd)
      .then((res) => {
        let response = res?.data?.response?.detail;
        setRecords(response);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("🚀 ~ file: inspection.jsx ~ API ~ err", err);
      });
  };

  const printPlaceholder = () => {
    return (
      <div key={Math.random()} style={{ marginTop: "2rem" }}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="rectangular" width="100%" height={80} />
      </div>
    );
  };

  const deleteRecord = (id) => {
    setIsLoading(true);
    API(`admin/delete-inspection/${id}`, "get", null)
      .then((res) => {
        closeModal();
        let msg = res.data.response.message;
        dispatch(sPopup({ open: true, message: msg }));
        getRecords();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const showModal = (id) => {
    setOpen(true);
    setInspecID(id);
  };

  const closeModal = () => {
    setOpen(false);
    setInspecID(null);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: "3rem", mb: "3rem" }}>
        <>
          <Typography variant="h4" component="h4">
            Inspections List
          </Typography>
          {isLoading ? (
            ["", "", ""].map(() => {
              return printPlaceholder();
            })
          ) : (
            <>
              {records?.data && (
                <InspectionList
                  data={records?.data}
                  showModal={showModal}
                />
              )}
              {records?.last_page < 2 ? null : (
                <Pagination
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "3rem ",
                  }}
                  count={records?.total}
                  page={page}
                  onChange={(event, newPage) => {
                    setPage(newPage);
                  }}
                />
              )}
            </>
          )}
        </>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are You Sure?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="end" mt={2}>
            <Button variant="outlined" onClick={() => closeModal()}>
              No
            </Button>
            <Button variant="contained" onClick={() => deleteRecord(InspecID)}>
              yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
}

const InspectionList = ({ data, showModal }) => {
  const navigation=useNavigate();
  return (
    <Grid container spacing={2} mt={2}>
      {data.length ? (
        data.map((item, index) => {
          return (
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} key={index}>
              <Paper elevation={10}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  sx={{ width: "100%", padding: "1rem" }}
                >
                  <Box
                    sx={{
                      "@media (min-width: 1400px)": { maxWidth: "170px" },
                      maxWidth: "120px",
                      alignSelf: "center",
                    }}
                  >
                    <img
                      src={item.front_picture_url}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </Box>
                  <Stack
                    direction="row"
                    p={1}
                    flexGrow={1}
                    justifyContent="space-between"
                  >
                    <Box
                      width="100%"
                      maxWidth="400px"
                      sx={{ padding: "0 1rem" }}
                    >
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        sx={{ color: "#333", fontWeight: "bold" }}
                      >
                        Name :{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {item.first_name + " " + item.last_name}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        sx={{ color: "#333", fontWeight: "bold" }}
                      >
                        State :{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {item.state}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        sx={{ color: "#333", fontWeight: "bold" }}
                      >
                        City :{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {item.city}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        sx={{ color: "#333", fontWeight: "bold" }}
                      >
                        Address :{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {item.address}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        sx={{ color: "#333", fontWeight: "bold" }}
                      >
                        Inspector :{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {item.inspector.name}
                        </span>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="h6"
                        sx={{ color: "#333", fontWeight: "bold" }}
                      >
                        Date of inspection :{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {item.date_of_inspection}
                        </span>
                      </Typography>
                      <Stack direction="row" spacing={2} mt={1}>
                        {!!item.pdf_url && (
                          <Tooltip title="Inspector pdf">
                            <Fab
                              size="small"
                              onClick={() =>
                                window.open(
                                  item.pdf_url,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              <PictureAsPdfIcon />
                            </Fab>
                          </Tooltip>
                        )}
                        {!!item.prolab_report_url && (
                          <Tooltip title="Prolab pdf">
                            <Fab
                              size="small"
                              onClick={() =>
                                window.open(
                                  item.prolab_report_url,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              <PictureAsPdfIcon />
                            </Fab>
                          </Tooltip>
                        )}
                        {!!item.final_pdf_url && (
                          <Tooltip title="Final pdf">
                            <Fab
                              size="small"
                              onClick={() =>
                                window.open(
                                  item.final_pdf_url,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              <PictureAsPdfIcon />
                            </Fab>
                          </Tooltip>
                        )}
                        {!!item.word_file_url && (
                          <Tooltip title="Word file">
                            <Fab
                              size="small"
                              onClick={() =>
                                window.open(
                                  item.word_file_url,
                                  "_blank",
                                  "noopener,noreferrer"
                                )
                              }
                            >
                              <TextSnippetIcon />
                            </Fab>
                          </Tooltip>
                        )}
                      </Stack>
                    </Box>
                    <Stack direction="column" spacing={2}>
                      <Tooltip title="View Inspection">
                        <Fab
                          size="small"
                          onClick={() => {
                            navigation(`/inspections/${item.inspection_id}`)
                          }}
                        >
                          <VisibilityIcon />
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Fab
                          size="small"
                          onClick={() => showModal(item.inspection_id)}
                        >
                          <DeleteForeverIcon sx={{ color: "red" }} />
                        </Fab>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
          );
        })
      ) : (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <h4 style={{ textAlign: "center", marginTop: 10, opacity: 0.5 }}>
            No data to display
          </h4>
        </Grid>
      )}
    </Grid>
  );
};
