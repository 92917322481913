import { Helmet } from "react-helmet";
import { Container, Grid, Paper, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import API from "../../axios.js";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
export default function Home() {
  const [cardsData, setCardsData] = useState();
  useEffect(() => {
    API("admin/dashboard", "get", null)
      .then((res) => {
        setCardsData(res.data.response.detail.dashboard_data);
      })
      .catch((err) => {
        console.error("🚀 ~ file: home.jsx ~ API ~ err", err);
      });
  }, []);
  return (
    <>
      <Head />
      <Container maxWidth="xl">
        <Grid container spacing={4} mt={2}>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card
              title="Total Inspectors"
              value={cardsData?.total_inspectors}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card
              title="Total Call Logs"
              value={cardsData?.total_call_logs}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card
              title="Total Inspections"
              value={cardsData?.total_inspections}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card
              title="Total Insurance Carriers"
              value={cardsData?.total_insurance_carriers}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card
              title="Total Jobs"
              value={cardsData?.total_jobs}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card
              title="Total Property Types"
              value={cardsData?.total_property_types}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card
              title="Total Trainings"
              value={cardsData?.total_trainings}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card
              title="Total Training Categories"
              value={cardsData?.total_training_categories}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
const Card = ({ title, value }) => {
  const format = (num) =>
    String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1,");
  return (
    <Box component={Paper} elevation="5" sx={{ padding: "2.5rem 2rem " }}>
      <Typography sx={{ typography: { xl: "h5", md: "h6" } }}>
        {/* <Typography variant="h4" component="h4" sx={{ sm:{fontSize: "1.5rem"} }}> */}
        {title}
      </Typography>
      <Typography
        mt={1}
        sx={{ typography: { xl: "h6", md: "body1" }, textAlign: "end" }}
      >
        {!!value ? format(Number(value)) : <CircularProgress />}
      </Typography>
    </Box>
  );
};

const Head = () => {
  return (
    <Helmet>
      <title>Home | Dashboard</title>
    </Helmet>
  );
};
