import { Helmet } from "react-helmet";
import { Container, Button, Stack, TextField, Typography, Modal, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CountrySelect, MyLoader } from "../../components";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import API from "../../axios";
import { useDispatch } from 'react-redux';
import { sPopup } from '../../store/reducer';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteBtn = (props) => {
  return (
    <Button onClick={() => props.showModal(props.params)}><DeleteIcon sx={{ color: 'red' }} /></Button>
  )
};


export default function States() {
  const [newForm, setNewForm] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState("");
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countries, setCountries] = useState(null);
  const dispatch = useDispatch();

  const [stateId, setStateId] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getRecords();
  }, [page]);

  useEffect(() => {
    if (!countries) {
      getCountries();
    }
  }, []);

  const getRecords = () => {
    API("admin/get-states", "get")
      .then((res) => {
        let response = res.data.response.detail.states;
        setRecords(response);
        createRows(response.data);
      })
      .catch((err) => {
        console.log("🚀 ~ file: property-types.jsx ~ API ~ err", err);
      });
  };

  const getCountries = () => {
    API("admin/get-countries", "get")
      .then((res) => {
        let response = res.data.response.detail.countries;
        setCountries(response);
      })
      .catch((err) => {
        console.log("🚀 ~ file: property-types.jsx ~ API ~ err", err);
      });
  };

  const createRows = (data) => {
    setRows([]);
    data.map((item) => {
      setRows((prev) => [
        ...prev,
        {
          id: item.id,
          Title: item.title,
          Country: item.country.name,
        },
      ]);
    });
  };

  const openForm = () => {
    setNewForm(true);
  };
  const setCountry = (value) => {
    setCountryId(value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    API("admin/state", "post", {
      title: title,
      country_id: countryId,
    })
      .then((res) => {
        setIsLoading(false);
        let msg = res.data.response.message;
        getRecords();
        setNewForm(false);
        dispatch(sPopup({ open: true, message: msg }));
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("🚀 ~ file: property-types.jsx ~ API ~ err", err);
      });
  };


  const deleteRecord = (id) => {
    setIsLoading(true);
    API(`admin/delete-state/${id}`, 'get').then(res => {
      setOpen(false);
      let msg = res.data.response.message;
      dispatch(sPopup({ open: true, message: msg }));
      getRecords();
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
    })
  }

  const showModal = (props) => {
    setOpen(true);
    setStateId(props.row.id);
  }

  const closeModal = () => {
    setOpen(false);
    setStateId(null);
  }

  return (
    <>
      <Head />
      {isLoading ? <MyLoader /> : null}
      <Container maxWidth="xl">
        {newForm ? (
          <form
            autoComplete="off"
            onSubmit={submitForm}
            style={{ marginTop: "3rem" }}
          >
            <Typography variant="h5" component="h5" mb={3}>
              Add State
            </Typography>
            <Stack direction="column" spacing={2}>
              <CountrySelect
                label="Select Country"
                data={countries}
                setCountry={setCountry}
              />
              <TextField
                style={{ width: "100%", marginTop: "0.5rem" }}
                name="titleValue"
                id="standard-basic"
                type="text"
                label="Title"
                variant="standard"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                required
              />
              <div style={{ alignSelf: "end" }}>
                <Button
                  type="button"
                  onClick={() => setNewForm(false)}
                  variant="contained"
                  sx={{
                    marginTop: "1rem",
                    marginRight: "1rem",
                    textTransform: "capitalize",
                    padding: "0.5rem 3rem",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    marginTop: "1rem",
                    textTransform: "capitalize",
                    padding: "0.5rem 3rem",
                  }}
                  disabled={!countryId || !title}
                >
                  Send
                </Button>
              </div>
            </Stack>
          </form>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="h4" marginTop="2rem">
            States
          </Typography>
          {!newForm ? (
            <Button
              type="button"
              variant="contained"
              sx={{ marginTop: "2rem", textTransform: "capitalize" }}
              endIcon={<AddIcon />}
              onClick={() => openForm()}
            >
              Add
            </Button>
          ) : null}
        </div>
        <div style={{ width: "100%", margin: "2rem auto" }}>
          <DataGrid
            columns={[
              { field: "Country", headerName: "Country", flex: 1 },
              { field: "Title", headerName: "Title", flex: 1 },
              { field: 'Action', headerName: 'Action', flex: 1, renderCell: (params) => <DeleteBtn params={params} showModal={showModal} /> },
            ]}
            loading={records === ""}
            rows={rows}
            autoHeight={true}
            rowsPerPageOptions={[10]}
            pageSize={records.per_page}
            page={page}
            rowCount={records.total}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            pagination
          />
        </div>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are You Sure?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="end" mt={2}>
            <Button variant="outlined" onClick={() => closeModal()}>No</Button>
            <Button variant="contained" onClick={() => deleteRecord(stateId)}>yes</Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
const Head = () => {
  return (
    <Helmet>
      <title>States | Dashboard</title>
    </Helmet>
  );
};
