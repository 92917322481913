import {
  Box,
  Button,
  ClickAwayListener,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function CustomSelect ({ label, data, setInspector }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState(label);
  const [checkValue, setCheckValue] = useState("");

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleOption = (item) => {
    setSelectedValue(item.name);
    setInspector(item.user_id);
    setOpen(false);
  };

  const handleSearch = (value) => {
    setCheckValue(value);
  };

  const printOptions = (item) => {
    return (
      <ListItem key={item.user_id} sx={{ padding: 0 }}>
        <ListItemButton
          sx={{ padding: "0.2rem 0.5rem" }}
          onClick={() => handleOption(item)}
        >
          <ListItemText>{item.name}</ListItemText>
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={{ width: "100%" }}>
        <Button
          variant="text"
          endIcon={<ExpandMoreIcon />}
          sx={selectButton}
          type="button"
          onClick={handleClick}
        >
          {selectedValue}
        </Button>
        {open ? (
          <div style={{ position: "relative" }}>
            <Box sx={selectContainer}>
              {/* <ListItem sx={{ padding: 1 }}>
                                      <input type="text" placeholder='Search' style={searchInput} onChange={(event) => handleSearch(event)} />
                                  </ListItem> */}
              {data.map((item) => {
                return printOptions(item);
              })}
            </Box>
          </div>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

const selectButton = {
  color: "#333",
  borderBottom: "1px solid #333",
  borderRadius: 0,
  width: "100%",
  justifyContent: "space-between",
  textTransform: "capitalize",
};

const searchInput = {
  width: "100%",
  maxWidth: "100%",
  fontSize: "0.85rem",
  padding: "0.5rem 0.2rem",
};
const selectContainer = {
  position: "absolute",
  top: 5,
  minWidth: "100%",
  left: 0,
  zIndex: 1,
  border: "1px solid #ccc",
  bgcolor: "#fff",
};
