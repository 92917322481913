import React from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Input,
  Paper,
  Snackbar,
  Alert
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import { login, sPopup } from "../../store/reducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import { MyLoader } from "../../components";
import Logo from "../../assets/Images/Logo"

export default function Login() {
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [isLoading, setisLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [popUP, setPopUP] = React.useState({
    open: false,
    message: "",
  });

  const handleClose = () => {
    setPopUP({ ...popUP, open: false });
  }

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleForm = (e) => {
    e.preventDefault();
    setisLoading(true)
    const url = process.env.REACT_APP_API_KEY;
    let fd = new FormData();
    fd.append('email', values.email);
    fd.append('password', values.password);
    axios.post(url + 'admin/login', fd).then((res) => {
      let token = res.data.response.detail.token_detail.access_token;
      let data = res.data.response.detail.admin;
      let msg = res.data.response.message;
      localStorage.setItem("ACCESS_TOKEN", token);
      dispatch(login(data));
      setisLoading(false);
      dispatch(sPopup({ open: true, message: msg }))
      setValues({
        ...values,
        values: { email: "", password: "", showPassword: false },
      });
      navigate("/");
    }).catch((err) => {
      setPopUP({ ...popUP, open: true, message: err.response.data.error.message });
      setisLoading(false);
    })
  };

  return (
    <>
      <Head />
      {isLoading ?
        <MyLoader />
        :
        null
      }
      <Container maxWidth={false} sx={{ backgroundColor: 'primary.dark', display: 'flex', justifyContent: 'center', alignItems: { alignItems: 'center', md: 'center' }, height: '100vh' }}>
        <Paper elevation={24} sx={{ m: 1 }}>
          <Box
            sx={{
              width: '100%',
              maxWidth: '500px',
              padding: { lg: "3rem", md: "2rem", padding: '2rem 1rem' },
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <img src={Logo} alt="logo" />
            </Box>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                paddingTop: "1rem",
                color: 'secondary.main',
                paddingBottom:"2rem",
                fontWeight: 'bold', 


              }}
              gutterBottom
              component="h4"
            >
              Welcome!
            </Typography>
            <form onSubmit={handleForm} autoComplete='off'>
              <FormControl sx={{ width: "100%" }} variant="standard">
                <InputLabel htmlFor="standard-adornment-email">Email</InputLabel>
                <Input
                  id="standard-adornment-email"
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={(event) => handleChange(event)}
                  required
                  endAdornment={
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl sx={{ mt: 3, width: "100%" }} variant="standard">
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  name="password"
                  value={values.password}
                  onChange={(event) => handleChange(event)}
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div style={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ marginTop: "3rem", textTransform: "capitalize",  }}
                  endIcon={<LoginIcon />}
                >
                  Login
                </Button>
              </div>
            </form>
          </Box>
          <Box sx={{ p: 1 }}>
            <Typography sx={{ textAlign: 'center', color: '#ccc' }}>@ Copyright {new Date().getFullYear()}</Typography>
          </Box>
        </Paper>
      </Container>

      {/* Fail Msg */}

      <Snackbar open={popUP.open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          {popUP.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const Head = () => {
  return (
    <Helmet>
      <title>Login | Dashboard</title>
    </Helmet>
  );
};
