import React from "react";
import { Helmet } from "react-helmet";
import {
    Container,
    Typography,
    Grid,
    FormControl,
    Button,
    Select,
    MenuItem,
    OutlinedInput,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../../../axios";
import { sPopup } from "../../../../store/reducer";
import { MyLoader } from "../../../../components";
import { useDispatch } from "react-redux";

export default function EditListing() {
    const [isLoading, setisLoading] = React.useState(false);
    const [record, setRecord] = React.useState(null);
    const [catOpt, setCatOpt] = React.useState(null);
    const [title, setTitle] = React.useState("");
    const [imgObj, setImgObj] = React.useState("");
    const [videoObj, setVideoObj] = React.useState("");
    const [pdfObj, setPdfObj] = React.useState("");
    const { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onImageChange = (e) => {
        const [file] = e.target.files;
        setImgObj(file);
    };
    const onVideoChange = (e) => {
        const [file] = e.target.files;
        setVideoObj(file);
    };
    const onPdfChange = (e) => {
        const [file] = e.target.files;
        setPdfObj(file);
    };

    const appendData = () => {
        var fd = new FormData();
        fd.append("title", title);
        fd.append("status", record.training.active);
        if (imgObj !== "") {
            fd.append("image", imgObj);
        }
        if (videoObj !== "") {
            fd.append("video", videoObj);
        }
        if (pdfObj !== "") {
            fd.append("pdf", videoObj);
        }
        if (catOpt !== null || !!catOpt) {
            fd.append("category_id", catOpt);
        }
        return fd;
    };

    const handleForm = (e) => {
        e.preventDefault();
        setisLoading(true);
        let fd = appendData();
        API(`admin/update-training/${id}`, "post", fd)
            .then((res) => {
                console.log("🚀 ~ file: edit-listing.js ~ line 50 ~ .then ~ res", res);
                setisLoading(false);
                let msg = res.data.response.message;
                dispatch(sPopup({ open: true, message: msg }));
                navigate("/training/listing");
            })
            .catch((err) => {
                console.log(
                    "🚀 ~ file: edit-listing.js ~ line 53 ~ handleForm ~ err",
                    err
                );
                setisLoading(false);
            });
    };

    React.useEffect(() => {
        getUserData();
    }, []);

    const getUserData = () => {
        setisLoading(true);
        API(`admin/training/${id}`, "get", null)
            .then((response) => {
                let res = response.data.response.detail.training_data;
                console.log("🚀 ~ file: edit-listing.js ~ line 61 ~ .then ~ res", res);
                setRecord(res);
                if(res?.training?.category_id){
                    setCatOpt(res?.training?.category_id);
                }else{
                    setCatOpt(res?.training_categories[0]?.training_category_id);
                }                
                setTitle(res?.training?.title);
                setisLoading(false);
            })
            .catch((err) => {
                setisLoading(false);
                console.log(err);
            });
    };

    return (
        <>
            <Head />
            {isLoading ? <MyLoader /> : null}
            <Container maxWidth="xl">
                <Typography variant="h4" component="h4" marginTop="4rem">
                    Edit New Training
                </Typography>
                <form
                    autoCapitalize="off"
                    onSubmit={handleForm}
                    style={{ maxWidth: "800px", marginRight: "auto" }}
                >
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ marginTop: "2rem" }}
                    >
                        <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "8px" }}>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{ color: "primary.main", fontWeight: "600" }}
                            >
                                Choose Category
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "8px" }}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={catOpt}
                                placeholder="Choose Category"
                                sx={{
                                    width: "100%",
                                    padding: "12px",
                                    height: "40px",
                                }}
                                onChange={(e) => setCatOpt(e.target.value)}
                            >
                                {record?.training_categories.map((opt) => {
                                    return (
                                        <MenuItem
                                            value={opt.training_category_id}
                                            key={opt.training_category_id}
                                        >
                                            {opt.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "8px" }}>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{ color: "primary.main", fontWeight: "600" }}
                            >
                                Title
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "8px" }}>
                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                <OutlinedInput
                                    sx={{
                                        width: "100%",
                                        padding: "12px",
                                        height: "40px",
                                    }}
                                    required
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Title"
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "15px" }}>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{ color: "primary.main", fontWeight: "600" }}
                            >
                                Image
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "15px" }}>
                            <FormControl variant="standard">
                                <input
                                    onChange={onImageChange}
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "15px" }}>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{ color: "primary.main", fontWeight: "600" }}
                            >
                                Video
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "15px" }}>
                            <FormControl variant="standard">
                                <input
                                    onChange={onVideoChange}
                                    type="file"
                                    accept="video/mp4,video/x-m4v,video/*"
                                />
                            </FormControl>
                        </Grid>
                        {/* New work to add pdf into training */}
                        <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "15px" }}>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{ color: "primary.main", fontWeight: "600" }}
                            >
                                PDF
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "15px" }}>
                            <FormControl variant="standard">
                                <input
                                    onChange={onPdfChange}
                                    type="file"
                                    accept="application/pdf"
                                />
                            </FormControl>
                        </Grid>
                        {/* New work to add pdf into training */}
                        <Grid item xs={12}>
                            <div style={{ display: "flex", justifyContent: "end" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        marginTop: "4rem",
                                        textTransform: "capitalize",
                                        padding: "0.5rem 3rem",
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    );
}

const Head = () => {
    return (
        <Helmet>
            <title>Edit Listing | Dashboard</title>
        </Helmet>
    );
};
