import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { sPopup } from "../../store/reducer";
import { useNavigate, useParams } from "react-router-dom";
import { MyLoader } from "../../components";

import API from "../../axios";

const SingleInspection = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [pdf, setPdf] = React.useState("");
  const [record, setRecord] = useState(null);
  const [sampleId, setSampleId] = useState(null);
  const [sampleImages, setSampleImages] = React.useState([]);
  const [isLoading, setisLoading] = React.useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getData();
  }, []);

  const getData = (e) => {
    setisLoading(true);
    API(`admin/get-inspection/${params.id}`, "get")
      .then((res) => {
        setRecord(res?.data.response?.detail?.inspection);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };

  const onPdfChange = (e) => {
    const [file] = e.target.files;
    setPdf(file);
  };

  const handleForm = (e) => {
    e.preventDefault();
    if (pdf !== undefined && pdf !== "") {
      setisLoading(true);
      let fd = new FormData();
      if (pdf !== undefined && pdf !== "") {
        fd.append("pdf", pdf);
      }
      API(`admin/store-prolab-pdf/${record?.inspection_id}`, "post", fd)
        .then((res) => {
          setPdf("");
          let msg = res.data.response.message;
          dispatch(sPopup({ open: true, message: msg }));
          setisLoading(false);
        })
        .catch((err) => {
          console.error(
            "🚀 ~ file: single-inspection.jsx ~ handleForm ~ err",
            err
          );
          setisLoading(false);
        });
    }
  };

  const handleMultiSelect = (e) => {
    setSampleImages([...sampleImages, ...e.target.files]);
  };

  const updateSamples = (e) => {
    e.preventDefault();
    if (sampleImages.length > 0) {
      let fd = new FormData();
      sampleImages.map((v, i) => {
        fd.append(`other_images[${i}]`, v);
      });
      setisLoading(true);
      API(`admin/update-sample/${sampleId}`, "post",fd)
        .then((res) => {
          setRecord(res?.data.response?.detail?.inspection);
          let msg = res?.data?.response?.message;
          dispatch(sPopup({ open: true, message: msg }));
          setSampleImages([]);
          setSampleId(null);
          setisLoading(false);
        })
        .catch((err) => {
          setisLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <MyLoader />
      ) : (
        <Container maxWidth="xl" sx={{ mt: "3rem", mb: "3rem" }}>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12}>
              <Fab onClick={() => navigate("/inspections")} size="small">
                <ArrowBackIcon />
              </Fab>
            </Grid>
            <Grid item xs={12} mt={4}>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#ccc",
                  p: 1,
                  textAlign: "center",
                }}
              >
                <img
                  src={record.front_picture_url}
                  style={{ width: "100%", maxWidth: "275px", height: "auto" }}
                />
              </Box>
            </Grid>
            <Grid item mt={4} xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        sx={{
                          backgroundColor: "#333",
                          color: "#fff",
                          fontSize: "1.4rem",
                        }}
                      >
                        Information
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>
                        {record.first_name + " " + record.last_name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>State</TableCell>
                      <TableCell>{record.state}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>City</TableCell>
                      <TableCell>{record.city}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Address</TableCell>
                      <TableCell>{record.address}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Zipcode</TableCell>
                      <TableCell>{record.zipcode}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Policy Number</TableCell>
                      <TableCell>{record.policy_number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Date of Inspection</TableCell>
                      <TableCell>{record.date_of_inspection}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Moisture</TableCell>
                      <TableCell>
                        {record.moisture_intrusion ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Mold Growth</TableCell>
                      <TableCell>{record.mold_growth ? "Yes" : "No"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Musty Odors</TableCell>
                      <TableCell>{record.musty_odros ? "Yes" : "No"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Water Damage</TableCell>
                      <TableCell>
                        {record.water_damage ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Property Type</TableCell>
                      <TableCell>{record?.property_type.text ?? "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Insurance Carrier Type</TableCell>
                      <TableCell>{record.insurance_carrier.text}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <form
                autoCapitalize="off"
                autoComplete="off"
                onSubmit={handleForm}
              >
                <Typography component="h5" mb={2.5} variant="h5">
                  Upload Pdf
                </Typography>
                <Box component="div">
                  <div style={{ display: "block" }}>
                    <FormControl variant="standard">
                      <input
                        onChange={onPdfChange}
                        type="file"
                        accept="application/pdf"
                      />
                    </FormControl>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      marginTop: "1.5rem",
                      marginLeft: "auto",
                      display: "block",
                      textTransform: "capitalize",
                      padding: "0.5rem 3rem",
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h5" mt={4} mb={3} variant="h5">
                Inspector Details
              </Typography>
              <Stack spacing={2} component={Paper} direction="row" p={1}>
                <Box width="105px">
                  <img
                    src={record.inspector.profile_image_url}
                    style={{ width: "100%", height: "auto" }}
                    alt="profile-image.png"
                  />
                </Box>
                <div className="img_cap">
                  <Typography variant="subtitle1" component="h6">
                    Name : {record.inspector.name}
                  </Typography>
                  <Typography variant="subtitle1" mt={1} component="h6">
                    Email : {record.inspector.email}
                  </Typography>
                  <Typography variant="subtitle1" mt={1} component="h6">
                    Phone : {record.inspector.phone}
                  </Typography>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h5" mt={4} mb={3} variant="h5">
                Samples
              </Typography>
              {record.samples &&
                record.samples.map((item, i) => {
                  return (
                    <Box component="div" mb={2} key={i}>
                      <Box width="100%" component={Paper} p={1}>
                        <Typography component="h6" variant="subtitle1">
                          Analysis : {item.analysis ?? "-"}
                        </Typography>
                        <Typography component="h6" variant="subtitle1" mt={1}>
                          Lab Result : {item.lab_result ?? "-"}
                        </Typography>
                        <Typography component="h6" variant="subtitle1" mt={1}>
                          Loaction : {item.location ?? "-"}
                        </Typography>
                        <Typography component="h6" variant="subtitle1" mt={1}>
                          Picture Note : {item.picture_note ?? "-"}
                        </Typography>
                        <Typography component="h6" variant="subtitle1" mt={1}>
                          Serial : {item.serial ?? "-"}
                        </Typography>
                        <Box component="div">
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              marginTop: "1.5rem",
                              marginLeft: "auto",
                              display: "block",
                              textTransform: "capitalize",
                              padding: "0.5rem 3rem",
                            }}
                            onClick={() => {
                              if (!!sampleId) {
                                setSampleId(null);
                                setSampleImages([]);
                                return;
                              }
                              setSampleId(item.inspection_sample_id);
                            }}
                          >
                            {!!sampleId ? "Cancel" : "Add More"}
                          </Button>
                          {sampleId && (
                            <form autoComplete="off" onSubmit={updateSamples}>
                              <Typography component="h5" mb={2.5} variant="h5">
                                Upload Images
                              </Typography>
                              <Box component="div">
                                <div style={{ display: "block" }}>
                                  <FormControl variant="standard">
                                    <input
                                      onChange={handleMultiSelect}
                                      multiple
                                      type="file"
                                      accept="image/*"
                                    />
                                  </FormControl>
                                </div>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  sx={{
                                    marginTop: "1.5rem",
                                    marginLeft: "auto",
                                    display: "block",
                                    textTransform: "capitalize",
                                    padding: "0.5rem 3rem",
                                  }}
                                >
                                  Save
                                </Button>
                              </Box>
                            </form>
                          )}
                        </Box>
                        <Stack
                          mt={3}
                          direction={{ xs: "column", sm: "row" }}
                          spacing={3}
                          flexWrap="wrap"
                          justifyContent="center"
                          alignItems="center"
                          onClick={handleOpen}
                        >
                          {item.images.map((item, i) => {
                            return (
                              <Fragment
                                key={i}
                              >
                                <Box
                                  width="100%"
                                  maxWidth="200px"
                                  p={"5px"}
                                >
                                  <img
                                    src={item.image_url}
                                    alt="sample-image.png"
                                    style={{
                                      width: "100%",
                                      border: "1px solid #ccc",
                                    }}
                                  />
                                </Box>
                              </Fragment>
                            );
                          })}
                        </Stack>
                      </Box>
                      <MyLightRoom
                        open={open}
                        handleClose={handleClose}
                        data={item.images}
                      />
                    </Box>
                  );
                })}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

const MyLightRoom = ({ open, handleClose, data }) => {
  const [index, setIndex] = useState(0);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "500px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ backgroundColor: "#fff" }}>
            <img
              src={data[index].image_url}
              key={Math.random()}
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
          <Stack
            width="100%"
            justifyContent="center"
            mt={3}
            flexDirection="row"
          >
            <Fab
              size="small"
              sx={{ marginX: 1 }}
              disabled={index == 0 ? true : false}
              onClick={() => {
                setIndex(index - 1);
              }}
            >
              Prev
            </Fab>
            <Fab
              size="small"
              sx={{ marginX: 1 }}
              disabled={index == data.length - 1 ? true : false}
              onClick={() => {
                setIndex(index + 1);
              }}
            >
              Next
            </Fab>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default SingleInspection;
