import React, { useRef } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/reducer/index";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import CategoryIcon from "@mui/icons-material/Category";
import ListIcon from "@mui/icons-material/List";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MyLoader from "../loader/loader";
import API from "../../axios";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Logo from "../../assets/Images/Logo";
import HandymanIcon from "@mui/icons-material/Handyman";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import LanguageIcon from "@mui/icons-material/Language";

export default function Aside({ open, toggleDrawer }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [isLoading, setIsLoading] = React.useState(false);
  const [expOpen, setExpOpen] = React.useState(true);

  const handleClick = () => {
    setExpOpen(!expOpen);
  };

  const signOut = () => {
    setIsLoading(true);
    API("logout", "post", null)
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("ACCESS_TOKEN");
          dispatch(logout());
          setIsLoading(false);
          navigate("/login");
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const changeRoute = (r) => {
    return navigate(r);
  };

  return (
    <>
      {isLoading ? <MyLoader /> : null}
      <Drawer
        variant={isMdUp ? "permanent" : "temporary"}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <List sx={{ mt: 2 }}>
          <ListItem>
            <Box sx={{ margin: "0 auto" }}>
              <img src={Logo} alt="logo" className="image-fluid" />
            </Box>
          </ListItem>
        </List>
        <div style={{ margin: "1rem 0" }}>
          <Divider />
        </div>
        <List className="listItemStyle">
          <NavItem
            text="Home"
            myFunction={() => changeRoute("/")}
            icon={
              <HomeIcon
                sx={{ color: "secondary.dark" }}
              />
            }
          />
          <NavItem
            text="Call Logs"
            myFunction={() => changeRoute("/call-logs")}
            icon={<AssignmentIcon sx={{ color: "secondary.dark" }} />}
          />
          <ListItemButton
            onClick={handleClick}
            sx={{ color: "secondary.dark" }}
          >
            <ListItemIcon>
              <InboxIcon sx={{ color: "secondary.dark" }} />
            </ListItemIcon>
            <ListItemText primary="Training" sx={{ color: "secondary.main" }} />
            {expOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={expOpen} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: 0 }}>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => changeRoute("/training/categories")}
              >
                <ListItemIcon>
                  <CategoryIcon sx={{ color: "secondary.dark" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Categories"
                  sx={{ color: "secondary.main" }}
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => changeRoute("/training/listing")}
              >
                <ListItemIcon>
                  <ListIcon sx={{ color: "secondary.dark" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Listings"
                  sx={{ color: "secondary.main" }}
                />
              </ListItemButton>
            </List>
          </Collapse>
          <NavItem
            text="Jobs"
            myFunction={() => changeRoute("/jobs")}
            icon={<HandymanIcon sx={{ color: "secondary.dark" }} />}
          />
          <NavItem
            text="Inspections"
            myFunction={() => changeRoute("/inspections")}
            icon={<FactCheckIcon sx={{ color: "secondary.dark" }} />}
          />
          <NavItem
            text="Property Types"
            myFunction={() => changeRoute("/property-types")}
            icon={<HomeWorkIcon sx={{ color: "secondary.dark" }} />}
          />
          <NavItem
            text="Users"
            myFunction={() => changeRoute("/users")}
            icon={<SupervisedUserCircleIcon sx={{ color: "secondary.dark" }} />}
          />
          <NavItem
            text="Insurance Carriers"
            myFunction={() => changeRoute("/insurance-carriers")}
            icon={<MonetizationOnIcon sx={{ color: "secondary.dark" }} />}
          />
          <NavItem
            text="States"
            myFunction={() => changeRoute("/states")}
            icon={<LanguageIcon sx={{ color: "secondary.dark" }} />}
          />
          <NavItem
            text="Terms-and Conditions"
            myFunction={() => changeRoute("/terms-and-conditions")}
            icon={<LanguageIcon sx={{ color: "secondary.dark" }} />}
          />
          <NavItem
            text="Logout"
            myFunction={signOut}
            icon={<LogoutIcon sx={{ color: "secondary.dark" }} />}
          />
        </List>
      </Drawer>
    </>
  );
}

const NavItem = (props) => {
  return (
    <ListItem sx={{ padding: 0 }}>
      <ListItemButton
        onClick={props.myFunction}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.text} sx={{ color: "secondary.main" }} />
      </ListItemButton>
    </ListItem>
  );
};
