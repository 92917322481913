import firebase from 'firebase/app';
const firebaseConfig = {
    apiKey: "AIzaSyAmoQ0iDb6Z6LM6EjRAhqhCjPYjeD2bea4",
    authDomain: "mold-free.firebaseapp.com",
    projectId: "mold-free",
    storageBucket: "mold-free.appspot.com",
    messagingSenderId: "1080328666181",
    appId: "1:1080328666181:web:9cb49a38c1a991ab9fb72c",
}

firebase.initializeApp(firebaseConfig);

export default firebase;