import React from 'react';
import { Helmet } from "react-helmet";
import { Container, Typography } from "@mui/material";
import API from "../../axios";
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'Caller_Image', headerName: 'Caller Image', flex:1,renderCell: (params) => <img src={params.value} width={60} height={60} />,},
  { field: 'Caller_Name', headerName: 'Caller Name', flex:1 },
  { field: 'Room_Name', headerName: 'Room Name', flex:1 },
  { field: 'Status', headerName: 'Status', flex:1 },
];

export default function CallLogs() {

  const [records, setRecords] = React.useState('');
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    getCallLogs();
  }, [page]);

  const createRows = (data) => {
    setRows([]);
    data.map(item => {
      var status = item.attended == true ? 'Attended' : 'Missed';
      var rand = Math.random();
      if(!!item.caller){
        setRows(prev => [...prev, {
          id: rand,
          Caller_Image: item?.caller.profile_image_url,
          Caller_Name: item?.caller.name,
          Room_Name: item.room_name,
          Status: status
        }])
      }
    })
  }

  const getCallLogs = () => {
    let fd=new FormData();
    fd.append('page',page+1);
    API('admin/call-logs', 'post',fd).then(res => {
      setRecords(res.data.response.detail);
      createRows(res.data.response.detail.data);
    }).catch(err => {
      console.log("🚀 ~ file: call-logs.jsx ~ line 10 ~ API ~ err", err);
    })
  }

  return (
    <>
      <Head />
      <Container maxWidth='xl' >
      <Typography variant="h4" component="h4" marginTop='2rem'>
        Call Logs
      </Typography>
        <div style={{width:'100%',margin:'2rem auto'}}>
          <DataGrid
            columns={columns}
            loading={records == ''}
            rows={rows}
            autoHeight={true}
            pageSize={records.per_page}
            rowsPerPageOptions={[10]}
            page={page}
            rowCount={records.total}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            pagination
          />
        </div>
      </Container>
    </>
  );
}

const Head = () => {
  return (
    <Helmet>
      <title>Call Logs | Dashboard</title>
    </Helmet>
  );
};
