import { Button, Switch, Typography, IconButton, Modal, Box, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import API from '../../axios';
import { MyLoader } from '../../components';
import { Container } from '@mui/system';
import { useDispatch } from 'react-redux';
import { sPopup } from '../../store/reducer';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Users() {
    const [isLoading, setIsLoading] = useState();
    const [records, setRecords] = useState('');
    const [rows, setRows] = useState([]);
    const [page, setPage] = React.useState(0);

    const [open, setOpen] = React.useState(false);
    const [userID, setUserID] = useState(null);
    const handleClose = () => setOpen(false);


    const dispatch = useDispatch();

    useEffect(() => {
        getRecords();
    }, [page]);

    const createRows = (data) => {
        setRows([]);
        data.map(item => {
            setRows(prev => [...prev, {
                id: item.user_id,
                User_Name: item.name,
                Status: item.active,
                User_Image: item.profile_image_url,
                Action: item.user_id
            }])
        })
    }

    const getRecords = () => {
        let fd = new FormData();
        fd.append('page', page + 1);
        API('admin/all-users', 'post').then(res => {
            let response = res.data.response.detail.users
            setRecords(response);
            createRows(response.data);
        }).catch(err => {
            console.log("🚀 ~ file: property-types.jsx ~ API ~ err", err);
        })
    }

    const deleteUser = (id) => {
        setIsLoading(true);
        API(`admin/delete-user/${id}`, 'get', null).then(res => {
            setOpen(false);
            let msg = res.data.response.message;
            dispatch(sPopup({ open: true, message: msg }));
            getRecords();
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })

    }
    const updateStatus = (props) => {
        console.log(props)
        const userId = props.id;
        const status = props.row.Status;
        let fd = new FormData();
        if (status == true) {
            fd.append('status', "disabled");
        } else {
            fd.append('status', "active");
        }
        fd.append('user_id', userId);
        setIsLoading(true);
        API('admin/user-update', 'post', fd).then(res => {
            setIsLoading(false);
            let msg = res.data.response.message;
            dispatch(sPopup({ open: true, message: msg }));

        }).catch(err => {
            setIsLoading(false);
        })
    }

    const showModal = (props) => {
        setOpen(true);
        setUserID(props.id);
    }

    const closeModal = () => {
        setOpen(false);
        setUserID(null);
    }

    return (
        <>
            <Head />
            {isLoading ?
                <MyLoader />
                :
                null
            }
            <Container maxWidth='xl'>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4" component="h4" marginTop='2rem'>
                        Users
                    </Typography>
                </div>
                <div style={{ width: '100%', margin: '2rem auto' }}>
                    <DataGrid
                        columns={[
                            { field: 'User_Image', headerName: 'User Image', flex: 1, renderCell: (params) => <img src={params.value} width={60} height={60} />, },
                            { field: 'User_Name', headerName: 'User Name', flex: 1 },
                            { field: 'Status', headerName: 'Status', flex: 1, renderCell: (params) => <Switch defaultChecked={params.row.Status ? true : false} onClick={() => updateStatus(params)} /> },
                            { field: 'Action', headerName: 'Action', flex: 1, renderCell: (params) => <IconButton onClick={() => showModal(params)}><DeleteIcon sx={{color: 'red'}} /></IconButton> },
                        ]}
                        loading={records == ''}
                        rows={rows}
                        autoHeight={true}
                        pageSize={records.per_page}
                        rowsPerPageOptions={[10]}
                        page={page}
                        rowCount={records.total}
                        paginationMode="server"
                        onPageChange={(newPage) => setPage(newPage)}
                        pagination
                    />
                </div>
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are You Sure?
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="end" mt={2}>
                        <Button variant="outlined" onClick={() => closeModal()}>No</Button>
                        <Button variant="contained" onClick={() => deleteUser(userID)}>yes</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}

const Head = () => {
    return (
        <Helmet>
            <title>Users | Dashboard</title>
        </Helmet>
    );
};