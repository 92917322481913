import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { MyLoader } from "../components";
import {
  Home,
  Login,
  CallLogs,
  VideoCalling,
  VideoCalling2,
  Category,
  Listing,
  ListingAdd,
  PropertyTypes,
  InsuranceCarriers,
  Jobs,
  Inspection,
  Users,
  EditListing,
  States,
  TermsAndConditions,
  SingleInspection
} from "../screens";
import Layout from "../layout";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../store/reducer";
import API from "../axios";

const ProtectedRoute = ({ user, children }) => {
  if (user == null) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const CheckUser = ({ user, children }) => {
  if (user !== null) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const myRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/call-logs",
    component: <CallLogs />,
  },
  {
    path: "/call/:id",
    component: <VideoCalling />,
  },
  {
    path: "/caller",
    component: <VideoCalling2 />,
  },
  {
    path: "/training/categories",
    component: <Category />,
  },
  {
    path: "/training/listing",
    component: <Listing />,
  },
  {
    path: "/training/add",
    component: <ListingAdd />,
  },
  {
    path: "/training/edit-listing/:id",
    component: <EditListing />,
  },
  {
    path: "/property-types",
    component: <PropertyTypes />,
  },
  {
    path: "/insurance-carriers",
    component: <InsuranceCarriers />,
  },
  {
    path: "/jobs",
    component: <Jobs />,
  },
  {
    path: "/inspections",
    component: <Inspection />,
  },
  {
    path: "/inspections/:id",
    component: <SingleInspection />,
  },
  {
    path: "/users",
    component: <Users />,
  },
  {
    path: "/states",
    component: <States />,
  },
  {
    path: "/terms-and-conditions",
    component: <TermsAndConditions />,
  }
];

export default function Router() {
  const user = useSelector((state) => state.storeReducer.user);
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    if (user == null) {
      let token = localStorage.getItem("ACCESS_TOKEN");
      if (token !== null) {
        API("admin/me", "get")
          .then((res) => {
            dispatch(login(res.data.response.detail));
            setisLoading(false);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              localStorage.removeItem("ACCESS_TOKEN");
              return <Navigate to="/login" replace />;
            }
          });
      } else {
        setisLoading(false);
      }
    }
    return;
  }, []);

  return (
    <>
      {isLoading ? (
        <MyLoader />
      ) : (
        <Routes>
          <Route
            path="/login"
            element={
              <CheckUser user={user}>
                <Login />
              </CheckUser>
            }
          />
          {myRoutes.map((Item) => {
            return (
              <Route
                key={Math.random() * 100}
                path={Item.path}
                element={
                  <ProtectedRoute user={user}>
                    <Layout>{Item.component}</Layout>
                  </ProtectedRoute>
                }
              />
            );
          })}
        </Routes>
      )}
    </>
  );
}
