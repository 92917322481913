import React from 'react';
import { useParams } from 'react-router-dom';
import * as T_Video from 'twilio-video';
import API from '../../axios';

export default function VideoCalling2() {
    const { id } = useParams();

    React.useEffect(() => {
        getCall();
    }, []);

    const getCall = () => {
        const token = localStorage.getItem("ACCESS_TOKEN");
        API('admin/create-call','post', null).then(res => {
            // console.log("🚀 ~ file: video-calling.jsx ~ line 20 ~ axios.get ~ res", res);
            T_Video.createLocalVideoTrack().then(track => {
                const localMediaContainer = document.getElementById('local-media');
                localMediaContainer.appendChild(track.attach());
                // console.log("inside")
            });
            T_Video.createLocalTracks({
                audio: true,
                video: {
                    width: 640
                }
            }).then(localTracks => {
                // console.log("create local tracks")
                return T_Video.connect(res.data.response.detail.call_access_token, {
                    name: res.data.response.detail.room_name,
                    recordParticipantsOnConnect: true,
                    tracks: localTracks
                });
            }).then(room => {
                // console.log("first then", room)
                const localParticipant = room.localParticipant;

                // console.log(`Connected to the Room as LocalParticipant "${localParticipant.identity}"`);

                // Log any Participants already connected to the Room
                room.participants.forEach(participant => {
                    // console.log(`Participant "${participant.identity}" is connected to the Room`);
                });

                // Log new Participants as they connect to the Room
                room.once('participantConnected', participant => {
                    // console.log(`Participant "${participant.identity}" has connected to the Room`);
                });

                // Log Participants as they disconnect from the Room
                room.once('participantDisconnected', participant => {
                    // console.log(`Participant "${participant.identity}" has disconnected from the Room`);
                    room.disconnect();
                });

                room.on('participantConnected', participant => {
                    // console.log(`Participant "${participant.identity}" connected`);

                    participant.tracks.forEach(publication => {
                        if (publication.isSubscribed) {
                            const track = publication.track;
                            document.getElementById('remote-media-div').appendChild(track.attach());
                        }
                    });

                    participant.on('trackSubscribed', track => {
                        document.getElementById('remote-media-div').appendChild(track.attach());
                    });
                });

                room.participants.forEach(participant => {
                    participant.tracks.forEach(publication => {
                        if (publication.track) {
                            document.getElementById('remote-media-div').appendChild(publication
                                .track.attach());
                        }
                    });

                    participant.on('trackSubscribed', track => {
                        document.getElementById('remote-media-div').appendChild(track.attach());
                    });
                });

                room.on('disconnected', room => {
                    // Detach the local media elements
                    room.localParticipant.tracks.forEach(publication => {
                        const attachedElements = publication.track.detach();
                        attachedElements.forEach(element => element.remove());
                    });
                });


            });
        }).catch(err => {
                // console.log(err)
            })
    }

    const participantConnected = (participant) => {
        // console.log('Participant "%s" connected', participant.identity);

        const div = document.createElement('div');
        div.id = participant.sid;
        // console.log("🚀 ~ file: video-calling.jsx ~ line 49 ~ participantConnected ~ participant", participant)
        div.innerText = participant.identity;

        participant.on('trackSubscribed', track => trackSubscribed(div, track));
        participant.on('trackUnsubscribed', trackUnsubscribed);

        participant.tracks.forEach(publication => {
            if (publication.isSubscribed) {
                trackSubscribed(div, publication.track);
            }
        });

        document.body.appendChild(div);
    }

    const participantDisconnected = (participant) => {
        // console.log('Participant "%s" disconnected', participant.identity);
        document.getElementById(participant.sid).remove();
    }

    const trackSubscribed = (div, track) => {
        div.appendChild(track.attach());
    }

    const trackUnsubscribed = (track) => {
        track.detach().forEach(element => element.remove());
    }

    return (
        <>
        <h1>
            Video Call Screen
            <div id='local-media'></div>
            <div id='remote-media-div'></div>
        </h1>
        </>
    );
}