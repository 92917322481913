import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as T_Video from "twilio-video";
import { Container } from "@mui/system";
import Helmet from "react-helmet";
import { Typography, IconButton, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import API from "../../axios";
import CircleIcon from "@mui/icons-material/Circle";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';

export default function VideoCalling() {
    const { id } = useParams();
    const [participantCount, setParticipantCount] = useState(0);
    const [localUser, setLocalUser] = useState(false);
    const [remoteUser, setRemoteUser] = useState(false);
    const [connected, setConnected] = useState(false);
    const [Permissions, setPermissions] = useState({
        isMicOff: false,
        isMicAvailable: null,
        isCameraOff: false,
        isCameraAvailable: null,
    });

    React.useEffect(() => {
        getPermissions();
        getCall();
    }, []);

    const addLocalVideo = () => {
        T_Video.createLocalVideoTrack({
            audio: true,
            video: true,
        }).then((track) => {
            setLocalUser(true);
            const localMediaContainer = document.getElementById("local-media");
            localMediaContainer.appendChild(track.attach());
            let firectEle = document.getElementById("local-media").firstElementChild;
            firectEle.setAttribute("width", "100%");
            firectEle.setAttribute("height", "100%");
        });
    };

    const getCall = () => {
        addLocalVideo();
        API("admin/attend-call/" + id, "get")
            .then((res) => {
                T_Video.createLocalTracks({
                    audio: true,
                    video: true,
                })
                    .then((localTracks) => {
                        console.log("create local tracks");
                        return T_Video.connect(res.data.response.detail.accessToken, {
                            name: res.data.response.detail.roomName,
                            tracks: localTracks,
                            video: true
                        });
                    })
                    .then((room) => {
                        console.log("Room Created", room);
                        setParticipantCount(room.participants.size + 1);

                        console.log("🚀 ~ file: video-calling.jsx ~ line 64 ~ .then ~ localParticipant", room.localParticipant);
                        
                        room.participants.forEach((participant) =>
                            participantConnected(participant)
                        );
                        room.on("participantConnected", (participant) =>
                            participantOnConnected(participant)
                        );
                        room.once("participantConnected", (participant) =>
                            participantOnceConnected(participant)
                        );

                        room.once("participantDisconnected", (participant) => participantOnceDisconnected(participant));
                        room.on("disconnected", (room) => participantOnDisconnected(room));
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const participantConnected = (participant) => {
        console.log("🚀 ~ file: video-calling.jsx ~ line 100 ~ participantConnected ~ participant", participant);

        participant.tracks.forEach((publication) => {
            if (publication.track) {
                document
                    .getElementById("remote-media-div")
                    .appendChild(publication.track.attach());
            }
        });

        participant.on("trackSubscribed", (track) => {
            document.getElementById("remote-media-div").appendChild(track.attach());
        });
        setParticipantCount(participant.size + 1);
    };
    const participantOnConnected = (participant) => {
        console.log("🚀 ~ file: video-calling.jsx ~ line 102 ~ participantOnConnected ~ participant", participant);

        participant.tracks.forEach((publication) => {
            if (publication.isSubscribed) {
                const track = publication.track;
                document
                    .getElementById("remote-media-div")
                    .appendChild(track.attach());
            }
        });

        participant.on("trackSubscribed", (track) => {
            document
                .getElementById("remote-media-div")
                .appendChild(track.attach());
        });
        document
            .getElementById("remote-media-div")
            .firstElementChild.setAttribute("width", "100%");
        document.getElementById("remote-media-div")
            .firstElementChild.setAttribute("height", "100%");
        setConnected(true);
    };
    const participantOnceConnected = (participant) => {
        console.log("🚀 ~ file: video-calling.jsx ~ line 108 ~ participantOnceConnected ~ participant", participant)
        setConnected(true);
    };
    const participantOnceDisconnected = (participant) => {
        console.log("🚀 ~ file: video-calling.jsx ~ line 107 ~ participantOnceDisconnected ~ participant", participant);
        setConnected(false);
    }
    
    const participantOnDisconnected = (room) => {
        console.log("🚀 ~ file: video-calling.jsx ~ line 104 ~ VideoCalling ~ room", room);
        // Detach the local media elements
        room.localParticipant.tracks.forEach(publication => {
            const attachedElements = publication.track.detach();
            attachedElements.forEach(element => element.remove());
        });
        setConnected(false);
    }

    const disconnectCall = () => {
        console.log("disconnect call");
        window.open("/", "_self");
    };

    // GET/SET Permissions for Videos

    const getPermissions = () => {
        console.log("Call For Permissions");
        let audioConstraints = { audio: true };
        let videoConstraints = { video: true };

        setPermisison(audioConstraints);
        setPermisison(videoConstraints);
    };

    const setPermisison = async (constraints) => {
        let stream = null;
        try {
            stream = await navigator.mediaDevices.getUserMedia(constraints);
        } catch (err) { }
    };

    const micHandler = () => {
        setPermissions({ ...Permissions, isMicOff: !Permissions.isMicOff });
        // let check = Permissions.isMicOff;
        // if (check) {
        //     LParticipant.audioTracks.forEach(function (audioTrack) {
        //         audioTrack.enable();
        //     });
        // } else {
        //     LParticipant.audioTracks.forEach(function (audioTrack) {
        //         audioTrack.disable();
        //     });
        // }
    };

    const cameraHandler = () => {
        setPermissions({ ...Permissions, isCameraOff: !Permissions.isCameraOff });
        // let check = Permissions.isMicOff;
        // if (check) {
        //     LParticipant.videoTracks.forEach(function (videoTrack) {
        //         videoTrack.enable();
        //     });
        // } else {
        //     LParticipant.videoTracks.forEach(function (videoTrack) {
        //         videoTrack.disable();
        //     });
        // }
    };

    return (
        <>
            <Head />
            <Container maxWidth="xl">
                <Typography
                    variant="h4"
                    component="h4"
                    marginTop="1em"
                    marginBottom="0.5em"
                    textAlign="center"
                    fontWeight="bold"
                >
                    Video Call
                </Typography>
                <div style={{ maxWidth: "800px", margin: "1rem auto 0" }}>
                    <Typography my="1em">
                        {participantCount} Participant has join the call
                    </Typography>
                    <div style={{ position: "relative" }}>
                        <TopBar>
                            <CircleIcon
                                sx={{
                                    fontSize: "10px",
                                    zIndex: "999",
                                    position: "absolute",
                                    top: "3px",
                                    left: "2px",
                                }}
                                color={connected ? "primary" : "error"}
                            />
                            <Tooltip title="Disconnect call">
                                <IconButton onClick={() => disconnectCall()}>
                                    <PhoneDisabledIcon color="error" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip
                                title={Permissions.isCameraOff ? "Off camera" : "On camera"}
                            >
                                <IconButton onClick={cameraHandler}>
                                    <VisibilityOffIcon
                                        color={Permissions.isCameraOff ? "error" : ""}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={Permissions.isMicOff ? "Off mic" : "On mic"}>
                                <IconButton onClick={micHandler}>
                                    <MicOffIcon color={Permissions.isMicOff ? "error" : ""} />
                                </IconButton>
                            </Tooltip>
                        </TopBar>
                        <PartVideoContainer className="local_caller">
                            {remoteUser ?
                                null :
                                <IconContainer>
                                    <PersonIcon sx={{ fontSize: "250px" }} />
                                </IconContainer>
                            }
                            {/* Remote Media Track */}
                            <div id="remote-media-div" style={{ zIndex: 5 }}></div>
                        </PartVideoContainer>
                        <LocalVideoContainer className="participant_container">
                            {localUser ?
                                null
                                :
                                <IconContainer>
                                    <PersonIcon sx={{ fontSize: "100px" }} />
                                </IconContainer>
                            }
                            {/* Local Media Track */}
                            <div id="local-media" style={{ zIndex: 5 }}></div>
                        </LocalVideoContainer>
                    </div>
                </div>
            </Container>
        </>
    );
}

const Head = () => {
    return (
        <Helmet>
            <title>Dashboard</title>
        </Helmet>
    );
};

const TopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const PartVideoContainer = styled.div`
  overflow: hidden;
  width: 100%;
  min-height: 250px;
  background-color: #ccc;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items:center;
  `;
const LocalVideoContainer = styled.div`
  overflow: hidden;
  background-color: #ccc;
  border: 1px solid black;
  min-height: 100px;
  width: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items:center;
`;
