import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user:null,
  s_Popup:{
    open:false,
    message:''
  },
}

export const myReducer = createSlice({
  name: 'storeReducer',
  initialState,
  reducers: {
    login: (state,action) => {
      state.user=action.payload 
    },
    register: (state,action) => {
      state.user=action.payload
    },
    logout:(state)=>{
      state.user=null
    },
    sPopup:(state,action)=>{
      state.s_Popup=action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, register, logout, sPopup } = myReducer.actions;

// export const selectUser=(state)=> state.user.user;

export default myReducer.reducer