import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { Container, Typography, Button, Switch, TextField, Stack, Modal } from "@mui/material";
import API from "../../../axios";
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useDispatch } from "react-redux";
import { sPopup } from "../../../store/reducer";
import { MyLoader } from "../../../components";
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';


const UpdateBtn = (props) => {
    return (
        <Button onClick={() => props.updateCat(props.params)}><ModeEditIcon /></Button>
    )
};

const DeleteBtn = (props) => {
    return (
        <Button onClick={() => props.showModal(props.params)}><DeleteIcon sx={{color: 'red'}} /></Button>
    )
};


export default function Category(e) {
    const [records, setRecords] = useState('');
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [newForm, setNewForm] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [formData, setFormData] = useState({
        title: ''
    });
    const [updateCategory, setUpdateCategory] = useState(null);
    const dispatch = useDispatch();

    const [catID, setCatID] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const updateCat = (item) => {
        setUpdateCategory(item);
        setFormData({ ...formData, title: item.row.Catogories_Title });
        setEditForm(true);
        setNewForm(false);
    }

    React.useEffect(() => {
        getCategories();
    }, [page]);


    const createRows = (data) => {
        setRows([]);
        data.map(item => {
            setRows(prev => [...prev, {
                id: item.training_category_id,
                Catogories_Title: item.title,
                Status: item.active,
            }])
        })
    }

    const getCategories = () => {
        let fd = new FormData();
        fd.append('page', page + 1);
        API('admin/training-categories', 'post', fd).then(res => {
            setRecords(res.data.response.detail);
            createRows(res.data.response.detail.training_categories.data);
        }).catch(err => {
            console.log(err);
        })
    }

    const updateStatus = ((props) => {
        const userId = props.id;
        const status = props.row.Status;
        let fd = new FormData();
        if (status == true) {
            fd.append('status', "not-active");
        } else {
            fd.append('status', "active");
        }
        setIsLoading(true);
        API(`admin/training-category/${userId}`, 'post', fd).then(res => {
            const userRecord = res.data.response.detail.training_category;
            let index = records.training_categories?.data?.findIndex(user => user.training_category_id == userId);
            records.training_categories.data[index] = userRecord;
            setRecords(records);
            createRows(records.training_categories.data);
            setIsLoading(false);
            let msg = res.data.response.message;
            dispatch(sPopup({ open: true, message: msg }));
        }).catch(err => {
            setIsLoading(false);
        })
    })

    const openForm = () => {
        setFormData({ ...formData, title: '' });
        setNewForm(true);
        setEditForm(false);
    }

    const handleInput = (e) => {
        setFormData({ ...formData, title: e.target.value });
    }

    const submitForm = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let fd = new FormData();
        fd.append('title', formData.title);
        let url = newForm ? 'admin/training-category' : `admin/training-category/${updateCategory.id}`;
        try {
            API(url, 'post', fd).then((res) => {
                setFormData({ title: '' });
                let msg = res.data.response.message;
                dispatch(sPopup({ open: true, message: msg }));
                getCategories();
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
            })
        } catch (e) {
            setIsLoading(false);
            console.log("🚀 ~ file: category.jsx ~ line 129 ~ submitForm ~ e", e)

        }
    }

    const deleteCat = (id) => {
        setIsLoading(true);
        API(`admin/delete-training-category/${id}`, 'get', null).then(res => {
            setOpen(false);
            let msg = res.data.response.message;
            dispatch(sPopup({ open: true, message: msg }));
            getCategories();
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    const showModal = (props) => {
        setOpen(true);
        setCatID(props.row.id);
    }

    const closeModal = () => {
        setOpen(false);
        setCatID(null);
    }

    return (
        <>
            <Head />
            {isLoading ?
                <MyLoader />
                :
                null
            }
            <Container maxWidth='xl'>
                {newForm ?
                    <form autoComplete='off' onSubmit={submitForm} style={{ marginTop: '3rem' }}>
                        <Typography variant="h5" component="h5">
                            Add New Category
                        </Typography>
                        <Stack direction="column" spacing={2}>
                            <TextField style={{ width: "100%", marginTop: "0.5rem" }} name="titleValue" id="standard-basic" type="text" label="Title" variant="standard" value={formData.title}
                                onChange={(event) => handleInput(event)}
                                required />
                            <div style={{ alignSelf: 'end' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ marginTop: "1rem", textTransform: "capitalize", padding: "0.5rem 3rem", }}
                                >
                                    Send
                                </Button>
                            </div>
                        </Stack>
                    </form>
                    : null
                }
                {editForm ?
                    <form autoComplete='off' onSubmit={submitForm} style={{ marginTop: '3rem' }}>
                        <Typography variant="h5" component="h5">
                            Edit Category <span style={{ opacity: '0.5' }}>({updateCategory.row.Catogories_Title})</span>
                        </Typography>
                        <Stack direction="column" spacing={2}>
                            <TextField style={{ width: "100%", marginTop: "0.5rem" }} name="titleValue" id="standard-basic" type="text" label="Title" variant="standard" value={formData.title}
                                onChange={(event) => handleInput(event)}
                                required />
                            <div style={{ alignSelf: 'end' }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{ marginTop: "1rem", textTransform: "capitalize", padding: "0.5rem 3rem", }}
                                >
                                    Send
                                </Button>
                            </div>
                        </Stack>
                    </form>
                    : null
                }
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h4" component="h4" marginTop='2rem'>
                        Categories
                    </Typography>
                    {!newForm ?
                        <Button
                            type="button"
                            variant="contained"
                            sx={{ marginTop: "2rem", textTransform: "capitalize" }}
                            endIcon={<AddIcon />}
                            onClick={() => openForm()}
                        >
                            Add New
                        </Button>
                        : null
                    }
                </div>
                <div style={{ width: '100%', margin: '2rem auto' }}>
                    <DataGrid
                        columns={[
                            { field: 'Catogories_Title', headerName: 'Title', flex: 1 },
                            { field: 'Status', headerName: 'Status', flex: 1, renderCell: (params) => <Switch defaultChecked={params.row.Status ? true : false} onClick={() => updateStatus(params)} /> },
                            { field: 'Action', headerName: 'Action', flex: 1, renderCell: (params) => <div><UpdateBtn params={params} updateCat={updateCat} /><DeleteBtn params={params} showModal={showModal} /></div> }
                        ]}
                        loading={records == ''}
                        rows={rows}
                        autoHeight={true}
                        pageSize={records.per_page}
                        rowsPerPageOptions={[10]}
                        page={page}
                        rowCount={records.total}
                        paginationMode="server"
                        onPageChange={(newPage) => setPage(newPage)}
                        pagination
                    />
                </div>
            </Container >

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Are You Sure?
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="end" mt={2}>
                        <Button variant="outlined" onClick={() => closeModal()}>No</Button>
                        <Button variant="contained" onClick={() => deleteCat(catID)}>yes</Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}

const Head = () => {
    return (
        <Helmet>
            <title>Categories | Dashboard</title>
        </Helmet>
    );
};
