import {
    Box,
    Button,
    ClickAwayListener,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography
  } from "@mui/material";
  import React, { useState } from "react";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import ExpandLessIcon from '@mui/icons-material/ExpandLess';
  
  export default function CountrySelect ({ label, data, setCountry }) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = useState(label);
  
    const handleClick = () => {
      setOpen((prev) => !prev);
    };
  
    const handleClickAway = () => {
      setOpen(false);
    };
  
    const handleOption = (item) => {
      setSelectedValue(item.name);
      setCountry(item.id);
      setOpen(false);
    };

    const printOptions = (item) => {
      return (
        <ListItem key={item.user_id} sx={{ padding: 0 }}>
          <ListItemButton
            sx={{ padding: "0.2rem 0.5rem" }}
            onClick={() => handleOption(item)}
          >
            <ListItemText >
            <Typography variant="h5" component="h5" sx={{ fontSize:'14px' }}>
                {item.name}
            </Typography></ListItemText>
          </ListItemButton>
        </ListItem>
      );
    };
  
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ width: "100%" }}>
          <Button
            variant="text"
            endIcon={open?<ExpandLessIcon/>:<ExpandMoreIcon />}
            sx={selectButton}
            style={open ?{borderColor:'#89D106'} : null}
            type="button"
            onClick={handleClick}
          >
            {selectedValue}
          </Button>
          {open ? (
            <div style={{ position: "relative" }}>
              <Box sx={selectContainer}>
                {data.map((item) => {
                  return printOptions(item);
                })}
              </Box>
            </div>
          ) : null}
        </Box>
      </ClickAwayListener>
    );
  };
  
  const selectButton = {
    color: "#333",
    borderBottom: "1px solid #333",
    borderRadius: 0,
    width: "100%",
    justifyContent: "space-between",
    textTransform: "capitalize",
  };  
  const selectContainer = {
    position: "absolute",
    top: 5,
    minWidth: "100%",
    left: 0,
    zIndex: 1,
    border: "1px solid #ccc",
    bgcolor: "#fff",
    overflowY: "auto",
    maxHeight: "250px",
  };
  