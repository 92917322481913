import "./App.css";
import { Helmet } from "react-helmet";
import Router from "./router/router";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: "#89D106",
      light: "##B3DC7E",
    },
    secondary: {
      light: '#FFFFFF',
      main: '#8E8E8E',
      dark: '#262626',
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Head />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

const Head = () => {
  return (
    <Helmet>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />

    </Helmet>
  );
};