import { Helmet } from "react-helmet";
import { Container, Button, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import API from "../../axios";
import { useDispatch } from 'react-redux';
import { sPopup } from '../../store/reducer';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function TermsAndConditions() {
    const [isLoading, setIsLoading] = useState();
    const dispatch = useDispatch();

    const [editorState, setEditorState] = useState();

    useEffect(() => {
        getRecords();
    }, []);

    const onEditorStateChange = (value) => {
        setEditorState(value);
    };

    const getRecords = () => {
        setIsLoading(true);
        API("admin/terms-and-conditions-content", "get")
            .then((res) => {
                let response = res.data.response.detail.t_and_c;
                console.log("🚀 ~ file: terms-and-conditions.jsx ~ line 32 ~ .then ~ response", response)
                if (response == '') {
                    const html = '<h1>Terms And Conditions</h1>';
                    const contentBlock = htmlToDraft(html);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const edState = EditorState.createWithContent(contentState);
                    setEditorState(edState)
                    setIsLoading(false);
                } else {
                    const contentBlock = htmlToDraft(response);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const edState = EditorState.createWithContent(contentState);
                    setEditorState(edState)
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                console.log("🚀 ~ file: property-types.jsx ~ API ~ err", err);
                setIsLoading(false);
            });
    };

    const submit = () => {
        setIsLoading(true);
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        API("admin/update-terms-and-conditions-content", "post", {
            content: html,
        })
            .then((res) => {
                setIsLoading(false);
                let msg = res.data.response.message;
                dispatch(sPopup({ open: true, message: msg }));
            })
            .catch((err) => {
                setIsLoading(false);
                dispatch(sPopup({ open: true, message: 'Something went wrong.Please try again' }));
                console.log("🚀 ~ file: property-types.jsx ~ API ~ err", err);
            });
    };

    return (
        <>
            <Head />
            <Container maxWidth="xl">
                <Typography variant="h4" component="h4" marginTop="2rem">
                    Terms And Conditions
                </Typography>
                <div style={{ width: "100%", margin: "2rem auto" }}>
                    {isLoading ?
                        <div>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <br />
                            <Skeleton variant="rectangular" height={60} />
                            <br />
                            <Skeleton variant="rounded" height={100} />
                        </div>
                        :
                        <div
                            style={{ border: "1px solid #000" }}
                        >
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                    }
                </div>
                <div style={{ textAlign: 'end' }}>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={submit}
                    >
                        Save
                    </Button>
                </div>
            </Container>
        </>
    );
}
const Head = () => {
    return (
        <Helmet>
            <title>Terms And Condition | Dashboard</title>
        </Helmet>
    );
};
