import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Typography,
  Button,
  IconButton,
  Switch,
  Modal,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import API from "../../../axios";
import { sPopup } from "../../../store/reducer";
import { MyLoader } from "../../../components";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from "@mui/system";

const DeleteBtn = (props) => {
  return (
    <Button onClick={() => props.showModal(props.params)}><DeleteIcon sx={{color: 'red'}} /></Button>
  )
};

export default function Listing() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [records, setRecords] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const [trainingID, setTrainingID] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const columns = [
    {
      field: "TrainingImage",
      headerName: "Training Image",
      flex: 1,
      renderCell: (params) => <img src={params.value} width={60} height={60} />,
    },
    { field: "Title", headerName: "Title", flex: 1 },
    { field: "Category", headerName: "Category", flex: 1 },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Switch
          defaultChecked={params.row.Status ? true : false}
          onClick={() => updateStatus(params)}
        />
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div>
          <IconButton
            params={params}
            onClick={() => updateListing(params)}
            color="primary"
            component="span"
          >
            <EditIcon />
          </IconButton>
          <DeleteBtn params={params} showModal={showModal} /></div>
      ),
    },
  ];

  const updateStatus = (props) => {
    const id = props.id;
    const status = props.row.Status;
    let fd = new FormData();
    if (status == true) {
      fd.append("status", "disabled");
    } else {
      fd.append("status", "active");
    }
    setIsLoading(true);
    API(`admin/update-training/${id}`, "post", fd)
      .then((res) => {
        setIsLoading(false);
        let msg = res.data.response.message;
        dispatch(sPopup({ open: true, message: msg }));
        let index = records.data?.findIndex((user) => user.training_id == id);
        records.data[index].active = !records.data[index].active;
        setRecords(records);
        createRows(records.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const updateListing = (props) => {
    const id = props.id;
    navigate(`/training/edit-listing/${id}`);
  };

  const createRows = (data) => {
    setRows([]);
    data.map((item) => {
      setRows((prev) => [
        ...prev,
        {
          item: item,
          id: item.training_id,
          TrainingImage: item.image_url,
          Category: item?.cat?.title,
          Status: item.active,
          Title: item.title,
        },
      ]);
    });
  };

  const getTraining = () => {
    let fd = new FormData();
    fd.append("page", page + 1);
    API("admin/get-trainings", "post", fd)
      .then((res) => {
        console.log("🚀 ~ file: listing.jsx ~ API ~ res", res);
        let response = res?.data?.response?.detail;
        setRecords(response);
        createRows(response?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    getTraining();
  }, [page]);

  const deleteTraining = (id) => {
    console.log("🚀 ~ file: category.jsx ~ line 138 ~ deleteTraining ~ id", id)
    setIsLoading(true);
    API(`admin/delete-training/${id}`, 'get', null).then(res => {
      console.log("🚀 ~ file: listing.jsx ~ line 150 ~ API ~ res", res)
      setOpen(false);
      let msg = res.data.response.message;
      dispatch(sPopup({ open: true, message: msg }));
      getTraining();
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
    })
  }

  const showModal = (props) => {
    setOpen(true);
    setTrainingID(props.row.id);
  }

  const closeModal = () => {
    setOpen(false);
    setTrainingID(null);
  }

  return (
    <>
      <Head />
      {isLoading ? <MyLoader /> : null}
      <Container maxWidth="xl">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="h4" marginTop="2rem">
            Trainings
          </Typography>
          <Button
            type="button"
            variant="contained"
            sx={{ marginTop: "2rem", textTransform: "capitalize" }}
            endIcon={<AddIcon />}
            onClick={() => navigate("/training/add")}
          >
            Add New
          </Button>
        </div>
        <div style={{ width: "100%", margin: "2rem auto" }}>
          <DataGrid
            columns={columns}
            loading={records == ""}
            rows={rows}
            autoHeight={true}
            pageSize={records.per_page}
            rowsPerPageOptions={[10]}
            page={page}
            rowCount={records?.total}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            pagination
          />
        </div>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are You Sure?
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="end" mt={2}>
            <Button variant="outlined" onClick={() => closeModal()}>No</Button>
            <Button variant="contained" onClick={() => deleteTraining(trainingID)}>yes</Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

const Head = () => {
  return (
    <Helmet>
      <title>Dashboard</title>
    </Helmet>
  );
};
