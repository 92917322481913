import {
  Box,
  Button,
  Fab,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import API from "../../axios";
import { DataGrid } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { sPopup } from "../../store/reducer";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { Helmet } from "react-helmet";
import { MyLoader } from "../../components";
import AddJob from "./add-job";
import EditJob from "./edit-job";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DeleteBtn = (props) => {
  return (
    <Button onClick={() => props.showModal(props.params)}>
      <DeleteIcon sx={{ color: "red" }} />
    </Button>
  );
};

export default function Jobs() {
  const columns = [
    {
      field: "FeatureImage",
      headerName: "Featured Image",
      flex: 1,
      renderCell: (params) => <img src={params.value} width={60} height={60} />,
    },
    { field: "Title", headerName: "Title", flex: 1 },
    { field: "Email", headerName: "Email", flex: 1 },
    { field: "Phone", headerName: "Phone", flex: 1 },
    { field: "Address", headerName: "Address", flex: 1 },
    { field: "State", headerName: "State", flex: 1 },
    { field: "PostalCode", headerName: "Postal Code", flex: 1 },
    { field: "City", headerName: "City", flex: 1 },
    {
      field: "Completed",
      headerName: "Completed",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.Completed ? (
            <CheckIcon color="primary" />
          ) : (
            <CloseIcon sx={{ color: "red" }} />
          )}
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => updateJob(params)}
            color="primary"
            component="span"
          >
            <EditIcon />
          </IconButton>
          <DeleteBtn params={params} showModal={showModal} />
        </div>
      ),
    },
  ];
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [formHandler, setFormHandler] = useState({
    open: false,
    type: null,
  });
  const [records, setRecords] = useState("");
  const [page, setPage] = useState(0);
  const [rows, setRows] = React.useState([]);
  const [jobID, setJobID] = useState(null);
  const [editRecord, setEditRecord] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const updateJob = (item) => {
    setFormHandler({ open: true, type: 'edit' });
    setEditRecord(item.row.item)
  };

  const getRecords = () => {
    let fd = new FormData();
    fd.append("page", page + 1);
    API("admin/get-jobs", "post", fd)
      .then((response) => {
        let res = response?.data?.response?.detail;
        setRecords(res);
        createRows(res?.data);
      })
      .catch((err) => {
        console.error("🚀 ~ file: jobs.jsx ~ API ~ err", err);
      });
  };

  const createRows = (data) => {
    setRows([]);
    data.map((item) => {
      setRows((prev) => [
        ...prev,
        {
          item: item,
          id: item.job_id,
          FeatureImage: item.feature_image_url,
          Title: item.title,
          Address: item.address,
          State: item.state,
          PostalCode: item.postal_code,
          City: item.city,
          Phone: item.phone,
          Email: item.email,
          Completed: item.completed,
        },
      ]);
    });
  };

  useEffect(() => {
    getRecords();
  }, [page]);

  const deleteRecord = (id) => {
    setIsLoading(true);
    API(`admin/delete-job/${id}`, "get", null)
      .then((res) => {
        setOpen(false);
        let msg = res.data.response.message;
        dispatch(sPopup({ open: true, message: msg }));
        getRecords();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const showModal = (props) => {
    setOpen(true);
    setJobID(props.row.id);
  };

  const closeModal = () => {
    setOpen(false);
    setJobID(null);
  };

  return (
    <>
      <Head />
      {isLoading ? <MyLoader /> : null}
      <Container maxWidth="xl" sx={{ mt: "3rem", mb: "3rem" }}>
        {formHandler.open ? (
          <>
            <Fab
              size="small"
              onClick={() => {
                setFormHandler({ open: false, type: null });
                setEditRecord(null);
              }}
            >
              <ArrowBackIcon fontSize="inherit" />
            </Fab>
            {formHandler.type == "add" ? (
              <AddJob refreshRecords={() => getRecords()} />
            ) : (
              <EditJob 
              refreshRecords={() => getRecords()}
              closeForm={() => setFormHandler({ open: false, type: null })}
              record={editRecord} />
            )}
          </>
        ) : (
          <>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" component="h4">
                Jobs
              </Typography>
              <Button
                variant="contained"
                endIcon={<AddIcon />}
                onClick={() => setFormHandler({ open: true, type: "add" })}
              >
                Add Job
              </Button>
            </Stack>
            <div style={{ width: "100%", margin: "2rem auto" }}>
              <DataGrid
                columns={columns}
                loading={records == ""}
                rows={rows}
                autoHeight={true}
                pageSize={records?.per_page}
                rowsPerPageOptions={[10]}
                page={page}
                rowCount={records?.total}
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage)}
                pagination
              />
            </div>
          </>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are You Sure?
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="end" mt={2}>
              <Button variant="outlined" onClick={() => closeModal()}>
                No
              </Button>
              <Button variant="contained" onClick={() => deleteRecord(jobID)}>
                yes
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Container>
    </>
  );
}

const Head = () => {
  return (
    <Helmet>
      <title>Property Types | Dashboard</title>
    </Helmet>
  );
};
