import React from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Typography,
  Grid,
  FormControl,
  Button,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import API from "../../../../axios";
import { sPopup } from "../../../../store/reducer";
import { MyLoader } from "../../../../components";
import { useDispatch } from "react-redux";

export default function ListingAdd() {
  const [isLoading, setisLoading] = React.useState(false);
  const [catData, setCatData] = React.useState([]);
  const [catOpt, setCatOpt] = React.useState(null);
  const [title, setTitle] = React.useState();
  const [imgUrl, setImgUrl] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [pdf, setPdf] = React.useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onImageChange = (e) => {
    const [file] = e.target.files;
    setImgUrl(file);
  };
  const onVideoChange = (e) => {
    const [file] = e.target.files;
    setVideo(file);
  };
  const onPdfChange = (e) => {
    const [file] = e.target.files;
    setPdf(file);
  };

  const handleForm = (e) => {
    e.preventDefault();
    if (title && imgUrl) {
      setisLoading(true);
      let fd = new FormData();
      fd.append("title", title);
      fd.append("category_id", catOpt);
      fd.append("image", imgUrl);
      if (video !== undefined && video !== "") {
        fd.append("video", video);
      }
      if (pdf !== undefined && pdf !== "") {
        fd.append("pdf", pdf);
      }
      API("admin/training", "post", fd)
        .then((res) => {
          setCatOpt("");
          setTitle("");
          setImgUrl("");
          setVideo("");
          setPdf("");
          let msg = res.data.response.message;
          setisLoading(false);
          dispatch(sPopup({ open: true, message: msg }));
          navigate("/training/listing");
        })
        .catch((err) => {
          console.error("🚀 ~ file: add-listing.jsx ~ handleForm ~ err", err)
          setisLoading(false);
        });
    }else{
        dispatch(sPopup({ open: true, message: 'Image And Title Field is required' }));
    }
    };

  React.useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    API("admin/training-categories", "post", null)
      .then((res) => {
        let cat = res.data.response.detail.training_categories.data;
        setCatOpt(cat[0].training_category_id);
        setCatData(cat);
      })
      .catch((err) => {
        console.error("🚀 ~ file: add-listing.jsx ~ getCategories ~ err", err)
      });
  };

  return (
    <>
      <Head />
      {isLoading ? <MyLoader /> : null}
      <Container maxWidth="xl">
        <Typography variant="h4" component="h4" marginTop="4rem">
          Add New Training
        </Typography>
        <form autoCapitalize="off" onSubmit={handleForm} style={{maxWidth:'800px',marginRight:'auto'}}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ marginTop: "2rem"}}
          >
            <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "8px" }}>
              <Typography
                variant="p"
                component="p"
                sx={{ color: "primary.main", fontWeight: "600" }}
              >
                Choose Category
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "8px" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={catOpt}
                required
                placeholder="Choose Category"
                sx={{
                  width: "100%",
                  padding: "12px",
                  height: "40px",
                }}
                onChange={(e) => setCatOpt(e.target.value)}
              >
                {catData.map((opt) => {
                  return (
                    <MenuItem
                      value={opt.training_category_id}
                      key={opt.training_category_id}
                    >
                      {opt.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "8px" }}>
              <Typography
                variant="p"
                component="p"
                sx={{ color: "primary.main", fontWeight: "600" }}            
              >
                Title
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "8px" }}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <OutlinedInput
                  sx={{
                    width: "100%",
                    padding: "12px",
                    height: "40px",
                  }}
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "15px" }}>
              <Typography
                variant="p"
                component="p"
                sx={{ color: "primary.main", fontWeight: "600" }}
              >
                Image
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "15px" }}>
              <FormControl variant="standard">
                <input
                  required
                  onChange={onImageChange}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "15px" }}>
              <Typography
                variant="p"
                component="p"
                sx={{ color: "primary.main", fontWeight: "600" }}
              >
                Video
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "15px" }}>
              <FormControl variant="standard">
                <input
                  onChange={onVideoChange}
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                />
              </FormControl>
            </Grid>
            {/* New work to add pdf into training */}
            <Grid item xs={12} sm={12} md={4} sx={{ marginTop: "15px" }}>
              <Typography
                variant="p"
                component="p"
                sx={{ color: "primary.main", fontWeight: "600" }}
              >
                PDF
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} sx={{ marginTop: "15px" }}>
              <FormControl variant="standard">
                <input
                  onChange={onPdfChange}
                  type="file"
                  accept="application/pdf"
                />
              </FormControl>
            </Grid>
            {/* New work to add pdf into training */}
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    marginTop: "4rem",
                    textTransform: "capitalize",
                    padding: "0.5rem 3rem",
                  }}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

const Head = () => {
  return (
    <Helmet>
      <title>Add Listing | Dashboard</title>
    </Helmet>
  );
};
